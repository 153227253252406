import { Component, ViewChild, Input, Output, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from '@app/@core/services/utility.service';
import { Router } from '@angular/router';
import { AgentService } from '@app/agent/services/agent.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@app/general/services/api/api.service';
import { PERMISSIONS } from '@app/@shared/role.constants';
@Component({
  selector: 'data-table',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss', './../../../../theme/ngx-datatable.scss'],
  providers: [DatePipe],
})
export class DataTableComponent implements OnInit {
  @Input() set tableValue(val: any) {
    this.tableData = val;
    this.rows = this.tableData;
  }
  @Input() set paginationData(value: any) {
    if (value) {
      this.pages = value;
    }
  }
  @ViewChild('bookingHistoryTable') table: any;
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  rows: any = [];
  temp: any;
  filterVal: any;
  public count = 100;
  public pageSize = 3;
  public limit = 10;
  public offset = 0;
  isDatatableLoading: boolean = false;
  tableData: any;
  agentInvoiceData: any;
  customerInvoiceData: any;
  user: any;
  allowDownloadingAgentInvoice: boolean = false;
  viewCustomerInvoice: boolean = false;
  pages = {
    limit: 10,
    totalCount: 10,
    offset: 0,
  };
  constructor(
    private utilityService: UtilityService,
    public dialog: MatDialog,
    private router: Router,
    private agentService: AgentService,
    private _snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    public apiService: ApiService
  ) {}

  ngOnInit() {
    this.user = this.utilityService.getUser();
    // Timeout used to prevent datatable UI breaking issue
    setTimeout(() => {
      this.isDatatableLoading = true;
    }, 1000);
  }

  public onPage(event: any): void {
    this.pageChange.emit(event);
  }
  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);
  }
  onDetailToggle(event: any) {
    console.log('Detail Toggled', event);
  }
  onSelect(event: any) {
    console.log(event);
  }

  downloadEticketInvoiceHandler(row: any) {
    let data = {
      reference: row.reference,
    };
    this.agentService.getETicketData(data).subscribe((response: any) => {
      if (response) {
        let blob = new Blob([response], { type: 'application/pdf' });
        let pdfUrl = window.URL.createObjectURL(blob);

        var PDF_link = document.createElement('a');
        PDF_link.href = pdfUrl;
        // TO OPEN PDF ON BROWSER IN NEW TAB
        window.open(pdfUrl, '_blank');
        // TO DOWNLOAD PDF TO YOUR COMPUTER
        PDF_link.download = 'e-ticket.pdf';
        PDF_link.click();
      }
    });
  }

  downloadAgentInvoiceHandler(row: any) {
    this.allowDownloadingAgentInvoice = true;
    this.viewCustomerInvoice = false;
    this.agentInvoiceData = {
      Id: row.reference,
    };
  }
  downloadCustomerInvoiceHandler(row: any) {
    this.viewCustomerInvoice = true;
    this.allowDownloadingAgentInvoice = false;
    this.customerInvoiceData = {
      Id: row.reference,
    };
  }
  itineraryView(row: any) {
    if (!this.isAdminportal()) {
      this.router.navigate(['/agent/itineraryView'], {
        queryParams: {
          Id: row.reference,
          dept_city: row.from_location,
          arr_city: row.to_location,
          triptype: row.trip,
        },
      });
    }
  }
  makePayment(data: any) {
    this.router.navigate(['/agent/view-reserved-itinerary'], {
      queryParams: {
        bookingRef: data.reference,
      },
      queryParamsHandling: 'merge',
    });
  }
  emailItinerary(data: any) {
    this.agentService.sendEmailItinerary(data.reference).subscribe((res: any) => {
      if (res?.success) {
        this._snackBar.open(res.message, 'X');
      }
    });
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  isAdminportal() {
    return this.utilityService.isAdminUser();
  }
  hasPermissionCancelBooking() {
    return this.utilityService.hasPermission(PERMISSIONS.CANCEL_BOOKING);
  }
  toDisplayFor_ClubHub() {
    return this.apiService.toDisplayForClubhub();
  }
  cancelBooking(row: any) {
    this.agentService.cancelBooking(row.reference).subscribe((res: any) => {
      this._snackBar.open(res.message, 'X');
    });
  }
}
