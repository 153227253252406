<div *ngIf="!isDatatableLoading">
  <app-spinner [isLoading]="!isDatatableLoading"></app-spinner>
</div>
<div class="bookingHistory" *ngIf="isDatatableLoading">
  <div class="table_data pl-0" *ngIf="rows.length !== 0">
    <ngx-datatable
      [rows]="rows"
      [limit]="pages.limit"
      class="material"
      [rowHeight]="55"
      [headerHeight]="50"
      [footerHeight]="pages.totalCount > 10 ? '50' : '0'"
      [scrollbarV]="false"
      [columnMode]="'force'"
      [externalPaging]="true"
      (page)="onPage($event)"
      [count]="pages.totalCount"
      [offset]="pages.offset"
      [reorderable]="false"
    >
      <ngx-datatable-column [width]="250" name="TRIP DATE">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="d-flex align-items-center" (click)="itineraryView(row)">
            <div class="date_Wrap mr-2 d-flex align-items-center">
              <div>
                <div class="d_date">{{ row.departure_date | date: 'dd' }}</div>
              </div>
              <div class="ml-1">
                <div class="d_mnth mb-1">{{ row.departure_date | date: 'MMM' }}</div>
                <div class="d_week">{{ row.departure_date | date: 'EEE' }}</div>
              </div>
            </div>
            <div>
              <span class="trip_view"> {{ row.from_location }}</span>

              <span *ngIf="row.trip !== 'MultiCity' && row.trip !== 'OpenJaw'"
                ><img src="./../../../assets/icons/flight_icn_devide.svg" alt="dir" width="40px" class="img_flights"
              /></span>
              <span *ngIf="row.trip == 'MultiCity' || row.trip == 'OpenJaw'"
                ><img
                  src="./../../../assets/icons/multy_city_arrows_mytrips.svg"
                  alt="dir"
                  width="13px"
                  class="img_flights mr-4 ml-4"
              /></span>
              <span class="trip_view">{{ row.to_location }}</span>
            </div>
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="STATUS">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">{{ row.status_str }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="TRIP ID">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">{{ row.reference }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="AGENCY NAME" *ngIf="isAdminportal()">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">{{ row.agency_name }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="BOOKED ON">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">
            {{ row.timestamp | date: 'd MMM  yyyy' }}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="AGENT NAME">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">
            {{ row?.agent_name }}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="EMAIL" [width]="200" *ngIf="!toDisplayFor_ClubHub()">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">
            {{ row.customer_email ? row.customer_email : 'N/A' }}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="PNR NUMBER" *ngIf="toDisplayFor_ClubHub()">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">
            {{ row?.pnrs ? row.pnrs : 'N/A' }}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="PAX NAME" [width]="200" *ngIf="toDisplayFor_ClubHub()">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)"> {{ row.first_name }} {{ row.last_name }} </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="SUPPLIER" *ngIf="toDisplayFor_ClubHub()">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">
            {{ row.company_names ? row.company_names : 'N/A' }}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="PROFIT" *ngIf="!toDisplayFor_ClubHub()">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">
            {{ row.currency_id | currencyCode }}{{ row.earning || 0 | number: '1.2-2' }}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="DISCOUNT" *ngIf="!apiService.toDisplayForWhitelabel()">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">
            {{ row.currency_id | currencyCode }}{{ row.discount || 0 | number: '1.2-2' }}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="AMOUNT">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="trip_view" (click)="itineraryView(row)">
            {{ row.currency_id | currencyCode }}{{ row.total_amount | number: '1.2-2' }}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="ACTIONS">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.status_str == 'Cancelled'">N/A</span>
          <span
            [matMenuTriggerFor]="theMenu"
            #t="matMenuTrigger"
            class="actions_CTA"
            *ngIf="row.status_str !== 'Cancelled'"
          >
            <img ImageSrc src="../../../../assets/icons/dashboard_Icons/more_actions.svg" alt="more_actions" />
          </span>
          <mat-menu #theMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="below">
            <button
              mat-menu-item
              class="mat_menu_btn"
              [disableRipple]="true"
              (click)="downloadEticketInvoiceHandler(row)"
              *ngIf="row.status_str != 'On Hold'"
            >
              <span class="float-left mr-2">
                <img src="../../../../assets/icons/dashboard_Icons/PDF.svg" alt="Pdf" />
              </span>
              <span translate class="d_labl">E - Ticket </span>
            </button>
            <button
              mat-menu-item
              class="mat_menu_btn"
              [disableRipple]="true"
              (click)="downloadAgentInvoiceHandler(row)"
              *ngIf="!toDisplayFor_ClubHub()"
            >
              <span class="float-left mr-2">
                <img src="../../../../assets/icons/dashboard_Icons/PDF.svg" alt="PDF" />
              </span>
              <span translate class="d_labl">Agent Invoice</span>
            </button>
            <button
              mat-menu-item
              class="mat_menu_btn"
              [disableRipple]="true"
              (click)="downloadCustomerInvoiceHandler(row)"
            >
              <span class="float-left mr-2">
                <img src="../../../../assets/icons/dashboard_Icons/PDF.svg" alt="PDF" />
              </span>
              <span translate class="d_labl">Customer Invoice </span>
            </button>
            <button
              mat-menu-item
              class="mat_menu_btn"
              [disableRipple]="true"
              (click)="makePayment(row)"
              *ngIf="row.status_str == 'On Hold' && !isAdminportal()"
            >
              <span translate class="d_labl">
                <span class="float-left mr-2">
                  <img src="../../../../assets/icons/dashboard_Icons/payment_card.svg" alt="payment_card" />
                </span>
                Make payment</span
              >
            </button>
            <button
              mat-menu-item
              class="mat_menu_btn"
              [disableRipple]="true"
              (click)="emailItinerary(row)"
              *ngIf="!isAdminportal()"
            >
              <span translate class="d_labl">
                <span class="float-left mr-2">
                  <img src="../../../../assets/icons/dashboard_Icons/email_msg.svg" alt="email_msg" /> </span
                >Email itinerary
              </span>
            </button>
            <button
              mat-menu-item
              class="mat_menu_btn"
              [disableRipple]="true"
              (click)="cancelBooking(row)"
              *ngIf="hasPermissionCancelBooking() && row.status_str !== 'Paid'"
            >
              <span translate class="d_labl">
                <span class="float-left mr-2">
                  <img src="../../../../assets/icons/dashboard_Icons/cancel-booking.svg" alt="cancel-booking" /> </span
                >Cancel booking
              </span>
            </button>
          </mat-menu>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
<div *ngIf="allowDownloadingAgentInvoice">
  <app-agent-invoice [agentInvoiceData]="agentInvoiceData"></app-agent-invoice>
</div>
<div *ngIf="viewCustomerInvoice">
  <app-customer-invoice [customerInvoiceData]="customerInvoiceData"></app-customer-invoice>
</div>
