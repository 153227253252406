import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Roles } from './@shared/role.constants';
import { AuthenticationGuard } from './auth/authentication.guard';
import { SignupComponent } from './auth/signup/signup.component';
import { HomeComponent } from './home/home.component';
import { IframeComponent } from './@shared/components/iframe/iframe.component';
import { PeachPaymentComponent } from './@shared/components/peach-payment/peach-payment.component';
import { RedirectgatewayComponent } from './payment/redirectgateway/redirectgateway.component';

const routes: Routes = [
  // Fallback when no prior route is matched
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'superadmin',
    component: SignupComponent,
  },
  {
    path: 'agent',
    loadChildren: () => import('./agent/agent.module').then((m) => m.AgentModule),
    canActivate: [AuthenticationGuard],
    data: { roles: [Roles.agent, Roles.subagent, Roles.staff] },
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule),
    canActivate: [AuthenticationGuard],
    data: { roles: [Roles.superadmin, Roles.tsemployee] },
  },
  {
    path: 'payments',
    loadChildren: () => import('../app/payment/payment.module').then((m) => m.PaymentModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'payments-method',
    loadChildren: () => import('../app/payment/payment.module').then((m) => m.PaymentModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'bookingFlights',
    component: IframeComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'redirectgateway',
    component: RedirectgatewayComponent,
  },
  {
    path: 'peach-payment',
    component: PeachPaymentComponent,
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
