import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UtilityService } from '@app/@core/services/utility.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { AgentService } from '@app/agent/services/agent.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@app/general/services/api/api.service';
import { PERMISSIONS } from '@app/@shared/role.constants';
@Component({
  selector: 'booking-data-card',
  templateUrl: './booking-data-card.component.html',
  styleUrls: ['./booking-data-card.component.scss'],
})
export class BookingDataCardComponent implements OnInit {
  @Input() set bookingHistory(val: any) {
    this.bookingHist = val;
  }
  bookingHist: any;
  invoiceOptionsIdForBookings: any;
  viewAgentInvoice: boolean = false;
  customerInvoiceData: any = {};
  agentInvoiceData: any = {};
  viewCustomerInvoice: boolean = false;
  user: any = null;
  constructor(
    private utilityService: UtilityService,
    public authService: AuthenticationService,
    public agentService: AgentService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private apiService: ApiService
  ) {}
  ngOnInit(): void {
    this.utilityService.userObj$.subscribe((user) => {
      this.user = user;
    });
    this.user = this.utilityService.getUser();
  }
  shouldShowInvoiceOptions(booking: any) {
    return this.invoiceOptionsIdForBookings === booking.from_location + booking.to_location + booking.reference;
  }
  showInvoiceOptions(booking: any) {
    if (this.invoiceOptionsIdForBookings) {
      this.invoiceOptionsIdForBookings = null;
    } else this.invoiceOptionsIdForBookings = booking.from_location + booking.to_location + booking.reference;
  }
  generateAgentInvoice(row: any) {
    this.viewAgentInvoice = true;
    this.viewCustomerInvoice = false;
    this.agentInvoiceData = {
      Id: row.reference,
    };
  }
  generateCustomerInvoice(row: any) {
    this.viewCustomerInvoice = true;
    this.viewAgentInvoice = false;
    this.customerInvoiceData = {
      Id: row.reference,
    };
  }
  itineraryView(booking: any) {
    if (!this.isAdminportal()) {
      this.router.navigate(['/agent/itineraryView'], {
        queryParams: {
          Id: booking.reference,
          dept_city: booking.from_location,
          arr_city: booking.to_location,
          triptype: booking.trip,
        },
      });
    }
  }
  makePayment(data: any) {
    this.router.navigate(['/agent/view-reserved-itinerary'], {
      queryParams: {
        bookingRef: data.reference,
      },
      queryParamsHandling: 'merge',
    });
  }
  emailItinerary(data: any) {
    this.agentService.sendEmailItinerary(data.reference).subscribe((res: any) => {
      if (res?.success) {
        this._snackBar.open(res.message, 'X');
      }
    });
  }
  cancelBooking(data: any) {
    this.agentService.cancelBooking(data.reference).subscribe((res: any) => {
      this._snackBar.open(res.message, 'X');
    });
  }
  downloadEticketInvoiceHandler(row: any) {
    let data = {
      reference: row.reference,
    };
    this.agentService.getETicketData(data).subscribe((response: any) => {
      if (response) {
        let blob = new Blob([response], { type: 'application/pdf' });
        let pdfUrl = window.URL.createObjectURL(blob);

        var PDF_link = document.createElement('a');
        PDF_link.href = pdfUrl;
        // TO OPEN PDF ON BROWSER IN NEW TAB
        window.open(pdfUrl, '_blank');
        // TO DOWNLOAD PDF TO YOUR COMPUTER
        PDF_link.download = 'e-ticket.pdf';
        PDF_link.click();
      }
    });
  }
  /**To find user is Admin or employee of organization */
  isAdminportal() {
    return this.utilityService.isAdminUser();
  }
  toDisplayForClubHub() {
    return this.apiService.toDisplayForClubhub();
  }
  hasPermissionCancelBooking() {
    return this.utilityService.hasPermission(PERMISSIONS.CANCEL_BOOKING);
  }
}
