<div class="bookingHistoryTab">
  <app-login-header></app-login-header>
  <div class="headerSec pb-lg-0 d-flex align-items-center" headerContent>
    <div class="header_Txt">
      <img
        (click)="backToDashboard()"
        alt="left-short-arrow"
        class="mr-2 back-page"
        src="../../../../assets/icons/back-arrow.svg"
      />
      Bookings
    </div>
    <div *ngIf="downloadable_Link" class="export-container mr-3 ml-auto" (click)="downloadFile('bookings-list.csv')">
      <img ImageSrc class="excel-Icon" src="../../../../assets/icons/dashboard_Icons/xlsx.svg" alt="xlsx" />
      Export Report
    </div>
  </div>
  <div class="bookingHistoryMatCardWrapper">
    <div class="table_tab" (selectedTabChange)="selected($event)">
      <div>
        <div class="sorting">
          <div class="sort_types">
            <ul class="ul_list text-right mb-1">
              <div class="d-none d-lg-block">
                <li>
                  <input
                    type="text"
                    class="form-control filter"
                    [formControl]="searchAll"
                    placeholder="{{ 'Search...' }}"
                    (keyup)="updateSearchFilter()"
                  />
                  <div class="filterIcon">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </div>
                </li>
                <li class="ml-2 pick_date">
                  <input
                    type="date"
                    class="form-control dates"
                    placeholder="{{ 'From Date' }}"
                    name="dp"
                    [formControl]="fromDate"
                    autocomplete="off"
                  />
                  <!-- daterangepicker close -->
                </li>
                <li class="ml-2 pick_date">
                  <input
                    type="date"
                    class="form-control dates"
                    placeholder="{{ 'To Date' }}"
                    name="dp"
                    [formControl]="toDate"
                    autocomplete="off"
                  />
                  <!-- daterangepicker close -->
                </li>
              </div>
            </ul>
          </div>
        </div>
        <app-spinner [isLoading]="isBookingHistoryLoading"></app-spinner>
        <div class="bookingHistoryTable d-none d-lg-block" *ngIf="!isBookingHistoryLoading && rows?.length !== 0">
          <data-table [tableValue]="rows" [paginationData]="pages" (pageChange)="onPage($event)"></data-table>
        </div>
        <div class="container err_section mb-4 pt-4 bg-white" *ngIf="rows?.length == 0 && !isBookingHistoryLoading">
          <div class="err_page text-center">
            <div class="container error_body">
              <div class="sec_img d-flex justify-content-center">
                <img class="img-fluid" src="../../../../assets/icons/nff-web.svg" alt="No Booking History" />
              </div>
              <div class="mt-3">
                <p translate class="err_msg">No Bookings found</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block d-lg-none bg-white" *ngIf="!isBookingHistoryLoading">
          <booking-data-card [bookingHistory]="rows"></booking-data-card>
          <div
            *ngFor="
              let booking of rows
                | paginate
                  : {
                      id: 'bookingPagination',
                      itemsPerPage: pages.limit,
                      currentPage: activePage,
                      totalItems: pages.totalCount
                    }
            "
            class="d-none"
          ></div>
          <div class="text-center">
            <pagination-controls
              id="bookingPagination"
              (pageChange)="onPageChange($event)"
              (pageBoundsCorrection)="onPageChange($event)"
              directionLinks="true"
              autoHide="true"
              responsive="true"
              previousLabel="Previous"
              nextLabel="Next"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="viewAgentInvoice">
  <app-agent-invoice [agentInvoiceData]="agentInvoiceData"></app-agent-invoice>
</div>
<div *ngIf="viewCustomerInvoice">
  <app-customer-invoice [customerInvoiceData]="customerInvoiceData"></app-customer-invoice>
</div>
