import { DOMAINS } from '@app/general/services/domains';
import { MANAGE_BOOKING_DEEPLINK } from 'src/constants/constants';

function getCityNamesfromJson(param: string, airports: any) {
  const airportInfos = airports;
  for (var i in airportInfos) {
    if (airportInfos[i].iata === param) {
      return airportInfos[i].city;
    }
  }
}
function getNegtiveAmount(amount: any) {
  return Math.abs(amount);
}
/**here we are checking passinging airline code is existed or not in odoList for displaying manage,check-in CTA's in flight-details-card component */
function OdoListHasAirline(airlineCode: any, OdoList: any) {
  let OdoListData = OdoList;
  let isAirlineAvl: boolean = false;
  if (OdoListData?.bookingFlightSegmentList?.length > 0) {
    isAirlineAvl = OdoListData.bookingFlightSegmentList.some((z: any) => z.marketingAirlineCode == airlineCode);
  }
  return isAirlineAvl;
}
/**here we are checking passinging supplier code is existed or not in itinerary for displaying manage CTA in flight-details-card component */
function checkItinhasSuppliers(reservations: any) {
  return Boolean(reservations?.supplierCode == 'VT' || reservations?.supplierCode == 'TX');
}
/**To get  airline code from first segment of itinerary  */
function getAirlineCode(itinerary: any) {
  return itinerary.originDestinationOptionsList[0].bookingFlightSegmentList[0].marketingAirlineCode;
}
/**To get pnr reference(Trip Identifier) for thomalex based on type from supplier references  */
function getPNR_Reference(itinerary: any) {
  let pnrReference: any = null;
  if (itinerary?.supplierReferences?.length > 0) {
    itinerary?.supplierReferences.forEach((ref: any) => {
      if (ref.type == 14) {
        pnrReference = ref.reference;
      }
    });
  }
  return pnrReference;
}
/**To manage booking deeplinks here we are passing required information to api then it will auto redirected to respective website &  we can move this method to utils once whitelabel is done*/
function manageBookingsDeeplinkUrl(
  airline_Code: string,
  supplierCode: string,
  pnrReference: string,
  itinerary_Data: any,
  userInfo: any
) {
  let supplerCode: any = supplierCode;
  let pnrNumber: any = pnrReference;
  let surName: any = itinerary_Data?.contactInfo?.personName?.surname;
  let agentId: any = userInfo?.agency_id;
  let airlineCode: any = airline_Code;
  let Url = `${MANAGE_BOOKING_DEEPLINK}?scode=${supplerCode}&bookingref=${pnrNumber}&surname=${surName}&agentid=${agentId}&acode=${airlineCode}`;
  return Url;
}

function generateIframeUrlByHostName() {
  let iframe_Link;
  DOMAINS.forEach((host: any) => {
    if (host.hostname === window.location.hostname) {
      iframe_Link = host.b2b_iframe_Link;
    }
  });
  return iframe_Link;
}
export {
  getCityNamesfromJson,
  getNegtiveAmount,
  OdoListHasAirline,
  checkItinhasSuppliers,
  getAirlineCode,
  getPNR_Reference,
  manageBookingsDeeplinkUrl,
  generateIframeUrlByHostName,
};
