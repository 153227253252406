import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  FLIGHT_SEARCH,
  LOAD_FLIGHTS,
  WALLET_BALANCE,
  BOOKING_LIST,
  DASHBOARD,
  CREATE_MARKUP,
  GET_AIRLINES,
  EDIT_MARKUP_BY_ID,
  GET_DESTINATIONS,
  CREATE_AGENT_USER,
  UPDATE_AGENT_USER,
  AGENT_PROFILE_UPDATE,
  AGENT_TOTAL_EARNINGS,
  BOOKING,
  AGENT_INVOICE_API,
  CUSTOMER_INVOICE_API,
  GET_ALL_EMAILTEMPLATES,
  PREVIEW_EMAILTEMPLATE,
  SAVE_EMAILTEMPLATE,
  AGENT_WALLET_TRANSACTIONS,
  GET_PAYMENT_DETAILS,
  AGENT_AUDIT_LOGS,
  TRAVELLER,
  TRAVELLER_EDIT,
  GET_PAYMENT_METHODS,
  GET_PROCESSING_FEE,
  INITIATE_PAYMENT_TRANSACTION,
  PAYMENT_VERIFY_CALL,
  E_TICKET,
  VIEW_ITINERARY,
  GET_MARKUP_TYPE,
  EMAIL_ITINERARY,
  CANCEL,
  WEBAPI,
} from 'src/constants/constants';
import { CredentialsService } from '@app/auth/credentials.service';
import { ApiService } from './../../general/services/api/api.service';
import { PAYMEMT_GATEWAY, PAYMENT_LINK } from '@app/general/services/api/api-paths';
@Injectable({
  providedIn: 'root',
})
export class AgentService {
  http: HttpClient;
  private BASE_URL: string;

  public userObj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public userObj$ = this.userObj.asObservable();
  selectedViewData = new BehaviorSubject(this.agentselectedViewData);
  constructor(http: HttpClient, public credentialsService: CredentialsService, private apiService: ApiService) {
    this.http = http;
    this.BASE_URL = this.apiService.apiBaseUrl();
  }
  loadFlights(searchTerm: any) {
    return this.http.get<any>(LOAD_FLIGHTS + 'n=6&q=' + searchTerm + '&locale=en_ZA');
  }

  searchFlight(searchObj: any): Observable<any> {
    if (searchObj.length >= 3) {
      return this.http.post(this.BASE_URL + FLIGHT_SEARCH, searchObj).pipe(
        map((res) => {
          return res;
        })
      );
    }
  }

  getWalletBalance(): Observable<any> {
    return this.http.get(this.BASE_URL + WALLET_BALANCE).pipe(
      map((balance) => {
        return balance;
      })
    );
  }
  getBookingHistory(params?: any): Observable<any> {
    return this.http.get(this.BASE_URL + BOOKING_LIST, { params }).pipe(
      map((balance) => {
        return balance;
      })
    );
  }
  getDashboard(): Observable<any> {
    return this.http.get(this.BASE_URL + DASHBOARD).pipe(
      map((res) => {
        return res;
      })
    );
  }
  updateAgentProfile(data: any) {
    return this.http.post(this.BASE_URL + AGENT_PROFILE_UPDATE, data);
  }
  addMarkup(data: any) {
    return this.http.post(this.BASE_URL + CREATE_MARKUP, data);
  }
  updateMarkup(id: any, data: any) {
    return this.http.post(this.BASE_URL + EDIT_MARKUP_BY_ID + '/' + id, data);
  }
  getAllMarkupList() {
    return this.http.get(this.BASE_URL + CREATE_MARKUP);
  }
  getAirlines(value: any, _limit: number) {
    return this.http.get(`${this.BASE_URL}${GET_AIRLINES}?n=${value}`);
  }
  getAirports(value: any) {
    return this.http.get(`${this.BASE_URL}${GET_DESTINATIONS}?n=${value}&p=b2c`);
  }
  createAgentUser(user: any) {
    return this.http.post(this.BASE_URL + CREATE_AGENT_USER, user);
  }
  getAgentUserList() {
    return this.http.get(this.BASE_URL + CREATE_AGENT_USER);
  }
  updateAgentUser(id: any, data: any) {
    return this.http.post(this.BASE_URL + UPDATE_AGENT_USER + '/' + id, data);
  }
  getAgentTotalEarning() {
    return this.http.get(this.BASE_URL + AGENT_TOTAL_EARNINGS);
  }
  getETicketData(Id: any) {
    return this.http.post(this.BASE_URL + BOOKING + E_TICKET, Id, { responseType: 'blob' });
  }
  getAgentInvoiceData(Id: any) {
    return this.http.post(this.BASE_URL + BOOKING + AGENT_INVOICE_API, Id, { responseType: 'blob' });
  }
  getCustomerInvoiceData(Id: any) {
    return this.http.post(this.BASE_URL + BOOKING + CUSTOMER_INVOICE_API, Id, { responseType: 'blob' });
  }
  getALLEmailTemplates() {
    return this.http.get(this.BASE_URL + GET_ALL_EMAILTEMPLATES);
  }
  previewEmailTemplates(id: number) {
    return this.http.get(this.BASE_URL + PREVIEW_EMAILTEMPLATE + '/' + id);
  }
  saveEmailTemplates(id: number, data: any) {
    return this.http.put(this.BASE_URL + SAVE_EMAILTEMPLATE + '/' + id, data);
  }
  publishEmailTemplates(id: number) {
    return this.http.post(this.BASE_URL + SAVE_EMAILTEMPLATE + '/' + id, '');
  }
  getPaymentsTilesInfo() {
    return this.http.get(this.BASE_URL + GET_PAYMENT_DETAILS);
  }

  getAgencyStaffDetail(id: any) {
    return this.http.get(this.BASE_URL + UPDATE_AGENT_USER + '/' + id);
  }

  getAgentWalletTransactions(params: any) {
    return this.http.get(this.BASE_URL + AGENT_WALLET_TRANSACTIONS, { params });
  }

  getAgentAuditLogs(params: any) {
    return this.http.get(this.BASE_URL + AGENT_AUDIT_LOGS, { params });
  }

  getTravellers() {
    return this.http.get(this.BASE_URL + TRAVELLER);
  }

  getTraveller(id: string) {
    return this.http.get(this.BASE_URL + TRAVELLER_EDIT + id);
  }

  editTraveller(id: string, data: any) {
    return this.http.post(this.BASE_URL + TRAVELLER_EDIT + id, data);
  }

  deleteTraveller(id: string) {
    return this.http.delete(this.BASE_URL + TRAVELLER_EDIT + id);
  }

  getPaymentMethods() {
    return this.http.get(this.BASE_URL + GET_PAYMENT_METHODS);
  }

  getProcessingFee(data: any) {
    return this.http.post(this.BASE_URL + GET_PROCESSING_FEE, data);
  }

  initiatePaymentTransaction(data: any) {
    return this.http.post(this.BASE_URL + INITIATE_PAYMENT_TRANSACTION, data);
  }

  paymentVerifyCall(data: any) {
    return this.http.post(this.BASE_URL + PAYMENT_VERIFY_CALL, data);
  }

  set selectedView(selected: any) {
    if (sessionStorage.getItem('seletedView')) {
      sessionStorage.removeItem('seletedView');
      sessionStorage.setItem('seletedView', selected);
    } else {
      sessionStorage.setItem('seletedView', 'booking_history');
    }
    this.selectedViewData.next(selected);
  }
  get agentselectedViewData(): string {
    return sessionStorage.getItem('seletedView');
  }

  download(file: string | undefined, link: any): Observable<Blob> {
    return this.http.get(link, {
      responseType: 'blob',
    });
  }
  downloadExcelFiles(filename: any, downloadable_Link: any) {
    this.download(filename, downloadable_Link).subscribe((file) => {
      const a = document.createElement('a');
      a.setAttribute('type', 'hidden');
      a.href = URL.createObjectURL(file);
      a.download = filename + '.xlsx';
      a.click();
      a.remove();
    });
  }
  viewItinerary(reference: string) {
    return this.http.get(this.BASE_URL + BOOKING + VIEW_ITINERARY + '/' + reference);
  }
  /**To generate payment link for agent to top-up wallet */
  generatePaymentLink(reqObj: any) {
    return this.http.post(this.BASE_URL + PAYMEMT_GATEWAY + PAYMENT_LINK, reqObj);
  }
  /**TO get markup types based on organization   */
  getMarkupTypes() {
    return this.http.get(this.BASE_URL + GET_MARKUP_TYPE);
  }
  /**To send email for reserved itinerary */
  sendEmailItinerary(bookingRef: any) {
    let reqObj = {
      reference: bookingRef,
    };
    return this.http.post(this.BASE_URL + BOOKING + EMAIL_ITINERARY, reqObj);
  }
  /**To send email for reserved itinerary */
  cancelBooking(bookingRef: any) {
    let reqObj = {
      reference: bookingRef,
    };
    return this.http.post(this.BASE_URL + WEBAPI + CANCEL, reqObj);
  }
}
