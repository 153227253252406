<mat-sidenav #sidenav mode="push" class="d-lg-none">
  <span class="float-right mt-2 pr-3 pl-3 close_nav" (click)="sidenav.toggle()"
    ><span>
      <img
        src="../../assets/icons/srp_Icons/close_cross_icn.svg"
        height="16px"
        width="16px"
        alt="close_cross_icn"
      /> </span
  ></span>
  <div class="clearfix"></div>
  <app-sidenav-list></app-sidenav-list>
</mat-sidenav>
<div class="agent_header" [ngClass]="isAgentLoginUserHeader ? 'agent_User_Header' : ''">
  <nav class="navbar navbar-inverse">
    <div class="container-fluid pl-lg-0">
      <div class="navbar-header mr-auto d-flex align-items-center">
        <button class="btn p-0 pr-2 d-lg-none" (click)="sidenav.toggle()">
          <span class="mat-button-wrapper"><i class="fa fa-bars" aria-hidden="true"></i></span>
        </button>
        <span
          (click)="homePage()"
          class="agency_name"
          *ngIf="
            isAgentLoginUserHeader && (responsiveservice.screenWidth == 'sm' || responsiveservice.screenWidth == 'md')
          "
        >
          <span *ngIf="!user.logo" class="userLogoTxt">{{ user.agency_name ? user.agency_name : user.name }}</span>
          <span *ngIf="user.logo">
            <img src="{{ user.logo }}" class="ml-0 userLogo" alt="user-logo" />
          </span>
        </span>

        <span
          class="backtoFlights d-flex align-items-center"
          (click)="navigateToFlights()"
          *ngIf="hasPermissionToAccessFlightSearch() && isAgentLoginUserHeader"
        >
          <span
            class="ImgSec"
            [style.display]="
              apiService.isFlightsiteDomain() &&
              (responsiveservice.screenWidth == 'sm' || responsiveservice.screenWidth == 'md')
                ? 'none'
                : 'block'
            "
          >
            <img ImageSrc src="../../../../assets/icons/dashboard_Icons/aeroplane.svg" alt="aeroplane" />
          </span>
          <div>
            <div>Book</div>
            <div class="flight_txt">Flights</div>
          </div>
        </span>
        <span
          *ngIf="apiService.isFlightsiteDomain() && isAgentLoginUserHeader"
          class="d-none backtoFlights align-items-center ml-lg-3"
          (click)="navigateToBuses()"
        >
          <span class="ImgSec d-none d-lg-block">
            <img src="../../../../assets/icons/dashboard_Icons/BUS_Icn.svg" alt="BUS_Icn" />
          </span>

          <div>
            <div>Book</div>
            <div class="flight_txt">Buses</div>
          </div>
        </span>
        <span
          *ngIf="apiService.isFlightsiteDomain() && isAgentLoginUserHeader"
          class="backtoFlights ml-lg-3 d-flex align-items-center"
          (click)="navigateToAccomodation()"
        >
          <span class="ImgSec d-none d-lg-block">
            <img src="../../../../assets/icons/dashboard_Icons/Hotel_Icn.svg" alt="Hotel_Icn" />
          </span>

          <div>
            <div>Book</div>
            <div class="flight_txt">Accommodation</div>
          </div>
        </span>
      </div>
      <div>
        <div class="header_leftMenu">
          <div
            (click)="navigateTocontactUs()"
            class="helpSec mr-2"
            *ngIf="isAgentLoginUserHeader && apiService.toDisplayForClubhub()"
          >
            <span class="d-flex align-items-center">
              <img src="../../../../assets/icons/dashboard_Icons/help-header.svg" alt="help-header" class="mr-1" />
              <span class="">Help</span>
            </span>
          </div>
          <div (click)="navigateToEmailsInbox()" *ngIf="hasPermissionForMailInbox()">
            <img
              class="marginBottom"
              [src]="
                isAgentLoginUserHeader
                  ? '../../../../assets/icons/dashboard_Icons/email.svg'
                  : '../../../../assets/icons/email.svg'
              "
              alt="email"
            />
            <span class="green-dot mail-dot" *ngIf="emails.length > 0"></span>
          </div>
          <div (click)="navigateToRejectionList()" *ngIf="hasPermissionForTicketingRejections()">
            <span *ngIf="!isAgentLoginUserHeader" class="d-flex align-items-center rejectionSec">
              <img
                src="../../../../assets/icons/dashboard_Icons/sidenav-rejections.svg"
                alt="sidenav-rejections"
                class="mr-2"
              />
              <span class="text-white">Rejections</span>
            </span>
          </div>

          <div class="user_profile_dropdown">
            <button [matMenuTriggerFor]="menu" class="user_btn btn" #matMenu="matMenuTrigger">
              <span class="d-flex justify-content-center">
                <span *ngIf="isAgentLoginUserHeader">
                  <img src="../../../../assets/icons/dashboard_Icons/user.svg" alt="user"
                /></span>

                <span *ngIf="!isAgentLoginUserHeader" class="d-flex align-items-center">
                  <span class="profile-icon text-center mr-2">{{ getLoggedInUserInitials() }}</span>
                  <span class="text-white d-none d-lg-block pr-2">{{ user?.name }}</span>
                </span>
              </span>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button (click)="profile()" mat-menu-item *ngIf="hasPermissionForProfileAgent()">
                <span>Profile</span>
              </button>
              <button
                (click)="navigateToAddmarkup()"
                *ngIf="hasPermissionToAddMarkup()"
                mat-menu-item
                [ngClass]="isAgent ? 'd-none' : ''"
              >
                <span>Add Markup</span>
              </button>
              <button
                (click)="createUser()"
                *ngIf="hasPermissionForUserCreation()"
                mat-menu-item
                [ngClass]="isAgent ? 'd-none' : ''"
              >
                <span>Create User</span>
              </button>
              <button
                (click)="staffList()"
                *ngIf="hasPermissionForStaffList()"
                mat-menu-item
                [ngClass]="isAgent ? 'd-none' : ''"
              >
                <span>Staff List</span>
              </button>
              <button
                (click)="navigateToAuditLogs()"
                *ngIf="hasPermissionForAuditLog()"
                mat-menu-item
                [ngClass]="isAgent ? 'd-none' : ''"
              >
                <span>Audit Logs</span>
              </button>
              <button
                (click)="navigateToRejectionList()"
                *ngIf="hasPermissionForTicketingRejections()"
                mat-menu-item
                [ngClass]="isAgent ? 'd-none' : ''"
              >
                <span>Ticketing Rejections</span>
              </button>
              <button mat-menu-item (click)="logout()">
                <span>Logout</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
