<div class="bg-white booking-card" *ngIf="bookingHist.length != 0">
  <div class="booking-card_item" *ngFor="let booking of bookingHist | slice: 0:5">
    <div class="booking-card_item_body">
      <div class="d-flex align-items-center mb-3" (click)="itineraryView(booking)">
        <div class="date d-flex align-items-center mr-3">
          <div class="mr-1">
            <h6>{{ booking.departure_date | date: 'dd' }}</h6>
          </div>
          <div>
            <span>{{ booking.departure_date | date: 'MMM' }}</span>
            <span>{{ booking.departure_date | date: 'EEE' }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <span class="flight-route-text">{{ booking.from_location }}</span>
          <div class="mx-2">
            <img src="./../../../assets/icons/flight_icn_devide.svg" width="40" alt="flight" />
          </div>
          <span class="flight-route-text">{{ booking.to_location }}</span>
          <span class="flight-subtext text-uppercase">you are flying</span>
        </div>
      </div>
      <div (click)="itineraryView(booking)">
        <div class="d-flex mb-2">
          <div>
            <span class="card-lable text-uppercase">trip id</span>
            <h6 class="card-text">{{ booking.reference }}</h6>
          </div>
          <div class="ml-auto text-right">
            <span class="card-lable text-uppercase">Booked On</span>
            <h6 class="card-text">{{ booking.timestamp | date: 'dd MMM yy' }}</h6>
          </div>
        </div>
        <div class="d-flex mb-2" *ngIf="toDisplayForClubHub()">
          <div>
            <span class="card-lable text-uppercase">Name</span>
            <h6 class="card-text">{{ booking.first_name }} {{ booking.last_name }}</h6>
          </div>
          <div class="ml-auto text-right">
            <span class="card-lable text-uppercase">Supplier</span>
            <h6 class="card-text">{{ booking.company_names ? booking.company_names : 'N/A' }}</h6>
          </div>
        </div>

        <div class="d-flex">
          <div>
            <span class="card-lable text-uppercase">status</span>
            <h6 class="card-text status-text">{{ booking.status_str }}</h6>
          </div>
          <div class="ml-auto text-right" *ngIf="toDisplayForClubHub()">
            <span class="card-lable text-uppercase">PNR Number</span>
            <h6 class="card-text">{{ booking?.pnrs ? booking.pnrs : 'N/A' }}</h6>
          </div>
        </div>
        <div class="d-flex">
          <div>
            <span class="card-lable text-uppercase">Agent Name</span>
            <h6 class="card-text">{{ booking?.agent_name }}</h6>
          </div>
          <div class="ml-auto text-right" *ngIf="isAdminportal()">
            <span class="card-lable text-uppercase">Agency Name</span>
            <h6 class="card-text">{{ booking.agency_name }}</h6>
          </div>
        </div>
      </div>
      <div *ngIf="booking.status_str != 'Cancelled'">
        <div class="w-100 mt-3 invoice-button" (click)="showInvoiceOptions(booking)">
          <span>Actions</span>
        </div>
        <div class="pt-2">
          <ul class="list-unstyled d-flex flex-wrap custom-button-group mb-0">
            <li
              class="btn-label"
              (click)="downloadEticketInvoiceHandler(booking)"
              *ngIf="booking.status_str != 'Cancelled' && booking.status_str != 'On Hold'"
            >
              <span class="label-with-icon">
                <img src="../../../../assets/icons/dashboard_Icons/PDF.svg" alt="agent-invoice" class="mr-1" /> E-Ticket
              </span>
            </li>
            <li class="btn-label" (click)="generateAgentInvoice(booking)" *ngIf="!toDisplayForClubHub()">
              <span class="label-with-icon">
                <img src="../../../../assets/icons/dashboard_Icons/PDF.svg" alt="agent-invoice" class="mr-1" /> Agent
                Invoice
              </span>
            </li>

            <li class="btn-label" (click)="generateCustomerInvoice(booking)">
              <span class="label-with-icon">
                <img src="../../../../assets/icons/dashboard_Icons/PDF.svg" alt="agent-invoice" class="mr-1" /> Customer
                Invoice
              </span>
            </li>
            <li
              class="btn-label"
              (click)="makePayment(booking)"
              *ngIf="booking.status_str == 'On Hold' && !isAdminportal()"
            >
              <span class="label-with-icon">
                <img src="../../../../assets/icons/dashboard_Icons/payment_card.svg" alt="payment_card" class="mr-1" />
                Make payment
              </span>
            </li>
            <li class="btn-label" (click)="emailItinerary(booking)" *ngIf="!isAdminportal()">
              <span class="label-with-icon">
                <img src="../../../../assets/icons/dashboard_Icons/email_msg.svg" alt="email_msg" class="mr-1" /> Email
                itinerary
              </span>
            </li>
            <li
              class="btn-label"
              (click)="cancelBooking(booking)"
              *ngIf="booking.status_str != 'Paid' && hasPermissionCancelBooking()"
            >
              <span class="label-with-icon">
                <img
                  src="../../../../assets/icons/dashboard_Icons/cancel-booking.svg"
                  alt="cancel-booking"
                  class="mr-1"
                />
                Cancel booking
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="booking-card_item_footer">
      <div class="d-flex">
        <div>
          <span class="card-lable text-uppercase">Earnings</span>
          <h6 class="card-text font-weight-semibold">{{ booking.currency_id | currencyCode }}{{ booking.earning }}</h6>
        </div>
        <div class="ml-auto text-right">
          <span class="card-lable text-uppercase">Amount</span>
          <h6 class="card-text font-weight-semibold">
            {{ booking.currency_id | currencyCode }}{{ booking.total_amount | number: '1.2-2' }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="viewAgentInvoice">
  <app-agent-invoice [agentInvoiceData]="agentInvoiceData"></app-agent-invoice>
</div>
<div *ngIf="viewCustomerInvoice">
  <app-customer-invoice [customerInvoiceData]="customerInvoiceData"></app-customer-invoice>
</div>
